import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {UrlConsumerService} from '../oauth/url-consumer.service';
import {ApsAttributes} from '../models/aps-attributes';
import {ActivatedRoute, Router} from '@angular/router';

/** Angular component that utilizes the PrimeNG p-menubar component
 * to display the navigation menu for the application
 */
@Component({
	selector: 'app-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
	navRole : any;
	navAdhocDisplayRole :any;
	apsAttributesSubject : ApsAttributes;
	/** Array of PrimeNG MenuItems that are used to populate the p-menubar */
	navMenuItems: MenuItem[];
	/** whether or not the sidebar is open or not */
	sidebarVisible: boolean;
	/** toggles visibility of sidebar to true */
	showSidebar = () => { this.sidebarVisible = true; };
	/** toggles visibility of sidebar to false */
	hideSidebar = () => { this.sidebarVisible = false; sessionStorage.setItem('siteOption','undefined');sessionStorage.removeItem('viewBy');sessionStorage.removeItem('programDropdown');};

	/** Create a nav component and populate with necessary MenuItems (static data) */
	constructor(private urlConsumerService: UrlConsumerService, private router: Router,
				public route: ActivatedRoute) {

	}

	ngOnInit(): void {
		this.urlConsumerService.apsAttributesSubject.subscribe(attributes => {
			this.apsAttributesSubject = attributes;
			if (this.apsAttributesSubject) {
				if((this.apsAttributesSubject.userRole==='ePSW Admin')
					||(this.apsAttributesSubject.userRole==='STASuperUser') || (this.apsAttributesSubject.userRole==='STAProgramEngineer')
					||(this.apsAttributesSubject.userRole==='STAProgramManager') ||(this.apsAttributesSubject.userRole==='STASrProgramManager')) {

					  this.navAdhocDisplayRole = true;
				}
					if((this.apsAttributesSubject.userRole==='ePSW Admin')
					||(this.apsAttributesSubject.userRole==='STASuperUser')){
					this.navRole = true;
					this.nav();
					console.log('true inside url');
			}else {
					this.navRole = false;
					this.nav();
					console.log('false inside url');
				}
		}});

		if((sessionStorage.getItem('navUserRole')==='ePSW Admin')
		||(sessionStorage.getItem('navUserRole')==='STASuperUser')){
			this.navRole = true;
			this.navAdhocDisplayRole = true;
			this.nav();
			console.log('true inside session');
		} else {
			this.navRole = false;
			this.navAdhocDisplayRole = false;
			this.nav();
			console.log('false inside session');
		}

		if((sessionStorage.getItem('navUserRole')==='STAProgramEngineer')
			||(sessionStorage.getItem('navUserRole')==='STAProgramManager') ||(sessionStorage.getItem('navUserRole')==='STASrProgramManager')){
			this.navAdhocDisplayRole = true;
			this.nav();
			console.log('true inside session');
		}



}

	nav(){
		this.navMenuItems = [
			{
				label: 'HOME PAGE',
				routerLink: '/home-page',
				command: this.hideSidebar
			},
			/*{
				label: 'DIGITAL PPAP PACKAGE',
				routerLink: '/DigitalPPAPPackageComponent',
				command: this.hideSidebar
			},
			{
				label: 'SUPPLIER VIEW',
				routerLink: '/ContinuedSupplierViewComponent',
				command: this.hideSidebar
			},*/
			{
				label: 'MASTER',
				visible: this.navRole,
				items: [
					{
						label: 'PPAP Assessment Section',
						routerLink: '/ppap-phase-assessment-section',
						visible: this.navRole,
						id: 'ppapPhaseAssessmentSection'
					},
					{
						label: 'PPAP Assessment Question\n',
						routerLink: '/ppap-phase-assessment-question',
						visible: this.navRole,
						id: 'ppapPhaseAssessmentQuestion'
					}
				]
			},
			{
			visible: this.navAdhocDisplayRole,
			label: 'ADHOC',
			routerLink: '/adhocreport',
			command: this.hideSidebar
	},
			{
				label: 'HELP',
				routerLink: '/help',
				command: this.hideSidebar
			},
		];

	}

	activeMenu(event: any) {
		if (event.target.innerText === 'HOME PAGE') {
			this.router.routeReuseStrategy.shouldReuseRoute = () => false;
			this.router.onSameUrlNavigation = 'reload';
			this.router.navigate(['./'], { relativeTo: this.route });
		}
		if (event.target.innerText === 'ADHOC') {
			this.router.routeReuseStrategy.shouldReuseRoute = () => false;
			this.router.onSameUrlNavigation = 'reload';
			this.router.navigate(['./adhocreport'], { relativeTo: this.route });
		}
	}
}
